import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const Wrapper = ({ children, ...props }) => (
  <dl className={styles.Wrapper} {...props}>
    {children}
  </dl>
)

/**
 */
export const Body = ({ children }) => (
  <dl className={styles.Body}>{children}</dl>
)

/**
 */
export const Container = ({ children, ...props }) => (
  <dl className={styles.Container} {...props}>
    {children}
  </dl>
)
