import React, { useContext, useEffect, useMemo } from 'react'

import * as Modal from '@arch-log/webapp.atomics/components/Modal'
import { useTranslation } from '@arch-log/webapp.lib/TranslationHelper'
import * as Collapse from '@arch-log/webapp.atomics/components/Collapse'

import * as Styled from './Styled'
import * as StyledBase from 'partials/CasesPage/SectionBase/Styled'
import { useMediaQuery } from 'react-responsive'

/**
 */
export const Header = ({
  components: { Decorator = StyledBase.Header } = {},
}) => {
  const { t } = useTranslation('app')

  const title = t('CasesPage.Lecture.Header.Title')
  const subTitle = t('CasesPage.Lecture.Header.SubTitle')

  return <Decorator title={title} subTitle={subTitle} />
}

/**
 */
export const Body = ({
  entries = [],
  components: {
    EntriesWrapper = Styled.EntriesWrapper,
    EntryWrapper = Styled.EntryWrapper,
  } = {},
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <EntriesWrapper>
      {entries.map((entry, index) => (
        <EntryWrapper key={index}>
          <Modal.Initializer
            // headerProps={{ wrapperProps: { style: { right: '110px' } } }}
            contentContainerProps={{
              style: {
                margin: isMobile ? '0' : '100px',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
          >
            <Entry entry={entry} />
          </Modal.Initializer>
        </EntryWrapper>
      ))}
    </EntriesWrapper>
  )
}

/**
 */
export const BodyWithCollapse = ({
  entries = [],
  size = 10,
  components: { Container = Styled.BodyWithCollapse_Container } = {},
}) => {
  const reshape = useMemo(
    () =>
      [...new Array(Math.ceil(entries.length / size))].map((_, i) => {
        const start = i * size
        const end = start + size

        return entries.slice(start, end)
      }),
    [],
  )

  return (
    <Container>
      {reshape.map((entries, i) => (
        <Collapse.Initializer collapsed={i === 0 ? false : true}>
          <EntriesWithCollapse
            key={i}
            entries={entries}
            start={i === 0 ? 1 : i + size}
            end={size + i * size}
          />
        </Collapse.Initializer>
      ))}
    </Container>
  )
}

/**
 */
export const EntriesWithCollapse = ({
  start,
  end,
  entries,
  components: {
    Container = Styled.EntriesWithCollapse_Container,
    HeaderWrapper = Styled.EntriesWithCollapse_Header,
    BodyWrapper = Styled.EntriesWithCollapse_Body,
  } = {},
}) => {
  const { isOpened, toggle } = useContext(Collapse.Context)

  return (
    <Container>
      <HeaderWrapper onClick={() => toggle()}>
        <Toggle isOpened={isOpened} />
        <Number start={start} end={end} />
      </HeaderWrapper>
      <BodyWrapper>{isOpened ? <Body entries={entries} /> : null}</BodyWrapper>
    </Container>
  )
}

/**
 */
export const Number = ({
  start,
  end,
  components: { Decorator = Styled.Number } = {},
}) => {
  const number = `No,${start} ~ ${end}`

  return <Decorator>{number}</Decorator>
}

/**
 */
export const Toggle = ({
  isOpened,
  components: { Close = Styled.Close, Open = Styled.Open } = {},
}) => {
  return isOpened ? <Close /> : <Open />
}

/**
 */
export const Entry = ({
  entry,
  components: { Render = Styled.Thumbnail } = {},
}) => {
  const { show } = Modal.useActions()

  return (
    <Render
      url={entry?.thumbnailUrl}
      onClick={() => show(() => <Styled.VideoRenderer url={entry?.url} />)}
    />
  )
}
