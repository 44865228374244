import React from 'react'

import * as Styled from './Styled'
import * as Literals from './Literals'

/**
 */
export const BackgroundImage = ({
  components: { Decorator = Styled.BackgroundImage } = {},
}) => <Decorator />

/**
 */
export const TextArea = ({
  components: { Decorator = Styled.TextArea } = {},
  children,
}) => <Decorator>{children}</Decorator>

/**
 */
export const Headline = ({
  components: { Decorator = Styled.Headline } = {},
}) => (
  <Decorator>
    <Literals.Headline />
  </Decorator>
)

/**
 */
export const Description = ({
  components: { Decorator = Styled.Description } = {},
}) => (
  <Decorator>
    <Literals.Description />
  </Decorator>
)
