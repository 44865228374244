import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const useActions = () => {
  const context = React.useContext(Context)
  const { contents, show, push, pop, hide } = context


  if (!context) {
    throw new Error(
      'Context is not Initialized. Please mount Modal.Initializer',
    )
  }

  return { contents, show, push, pop, hide }
}
