import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Headline = translated('app')('CasesPage.Headline')

/**
 */
export const Description = translated('app')('CasesPage.Description')
