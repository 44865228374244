import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const BackgroundImage = () => (
  <div className={styles.BackgroundImage}></div>
)

/**
 */
export const Headline = ({ children }) => (
  <h2 className={styles.Headline}>{children}</h2>
)

/**
 */
export const Description = ({ children }) => (
  <p className={styles.Description}>{children}</p>
)

/**
 */
export const TextArea = ({ children }) => (
  <div className={styles.TextArea}>{children}</div>
)
