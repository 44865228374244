import React from 'react'

import * as Entry from '../Entry'
import { Context } from '../Context'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 */
export const Each = ({ render: Renderer = ChildrenRenderer, ...props }) => {
  const { entries } = React.useContext(Context)

  return entries.map((entry, index) => {
    return (
      <Entry.Provider key={index} entry={entry}>
        <Renderer {...props} />
      </Entry.Provider>
    )
  })
}
