import React from 'react'
import { useAnkerLinks } from '.'

import * as Styled from './Styled'

/**
 */
export const AnkerLinks = ({
  components: {
    Container = Styled.Container,
    Entry = Styled.Entry,
    Decorator = Styled.Decorator,
    Link = Styled.Link,
  } = {},
}) => {
  const { entries } = useAnkerLinks()

  return (
    <Container>
      {entries.map((entry) => (
        <Link to={`${entry?.name}`} smooth={true}>
          <Entry>
            <Decorator>{entry?.label}</Decorator>
          </Entry>
        </Link>
      ))}
    </Container>
  )
}
