import React from 'react'

import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 */
export const Default = ({
  components: {
    Container = Layout.Container,
    AnkerLinks = Containers.AnkerLinks,
  } = {},
}) => {
  return (
    <Container>
      <AnkerLinks />
    </Container>
  )
}
