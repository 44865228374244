import React from 'react'

import * as styles from './styles.module.scss'
import { Link as Scroll } from 'react-scroll'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const Entry = ({ children }) => (
  <div className={styles.Entry}>{children}</div>
)

/**
 */
export const Decorator = ({ children, ...props }) => (
  <div className={styles.Decorator} {...props}>
    {children}
  </div>
)

/**
 */
export const Link = ({ children, ...props }) => (
  <Scroll className={styles.Link} {...props}>
    {children}
  </Scroll>
)
