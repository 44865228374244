import React, { useMemo } from 'react'
import { Context } from './Context'
import { useStaticQuery, graphql } from 'gatsby'

/**
 */
export const Initializer = ({ language = 'ja', children }) => {
  const { results } = useStaticQuery(
    graphql`
      query {
        results: service {
          ja: howtoLectureMovies(language: ja) {
            url
            thumbnailUrl
          }
          en: howtoLectureMovies(language: en) {
            url
            thumbnailUrl
          }
        }
      }
    `,
  )

  const entries = useMemo(
    () => results?.[language] ?? []
    [results]
  )

  return (
    <Context.Provider
      value={{
        entries,
      }}
    >
      {children}
    </Context.Provider>
  )
}
