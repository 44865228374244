import React from 'react'
import * as styles from './styles.module.scss'
import * as Languages from '@arch-log/webapp.modules/i18n/components/Languages'
import * as DocumentGroups from 'modules/howto.document'
import * as Containers from './Containers'
import * as Layout from './Layout'
import { Name } from '../AnkerLinks'

/**
 */
export const Default = ({
  components: {
    Title: titleComponents,
    DocumentUrl: documentUrlComponents,
    Layout: {
      Wrapper = Layout.Wrapper,
      Body = Layout.Body,
      Container = Layout.Container,
    } = {},
  } = {},
}) => {
  const { language } = React.useContext(Languages.Context)

  return (
    <Container id={Name.HowTo}>
      <Containers.Header />
      <Body>
        <DocumentGroups.Initializer language={language}>
          <DocumentGroups.Entries.Each>
            <Wrapper>
              <Containers.Title components={titleComponents} />
              <DocumentGroups.Entry.Documents.Each>
                <Containers.DocumentUrl components={documentUrlComponents} />
              </DocumentGroups.Entry.Documents.Each>
            </Wrapper>
          </DocumentGroups.Entries.Each>
        </DocumentGroups.Initializer>
      </Body>
    </Container>
  )
}
