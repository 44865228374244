import React from 'react'

import { Context } from './Context'

/**
 */
export const Initializer = ({ children, collapsed }) => {
  const [isOpened, setIsOpened] = React.useState(collapsed ? false : true)

  const open = () => {
    setIsOpened(true)
  }

  const close = () => {
    setIsOpened(false)
  }

  const toggle = () => {
    setIsOpened(!isOpened)
  }

  return (
    <Context.Provider value={{ isOpened, open, close, toggle }}>
      {children}
    </Context.Provider>
  )
}
