// extracted by mini-css-extract-plugin
export var BodyWithCollapse_Container = "styles-module--BodyWithCollapse_Container--uynm0";
export var Container = "styles-module--Container--IPjMR";
export var EntriesWithCollapse_Container = "styles-module--EntriesWithCollapse_Container--+UTGY";
export var EntriesWithCollapse_Header = "styles-module--EntriesWithCollapse_Header--QAQJm";
export var EntriesWrapper = "styles-module--EntriesWrapper--FRkoy";
export var EntryWrapper = "styles-module--EntryWrapper--9DMaI";
export var Header = "styles-module--Header--7mPwK";
export var Header_SubTitle = "styles-module--Header_SubTitle--7FmTL";
export var Header_Title = "styles-module--Header_Title--IAAX3";
export var Thumbnail = "styles-module--Thumbnail--8ULPf";
export var VideoRenderer = "styles-module--VideoRenderer--2zn6p";