import React from 'react'

import * as Sections from './Sections'
import * as Lecture from './Lecture'
import * as AnkerLinks from './AnkerLinks'
import * as Containers from './Containers'
import * as Layout from './Layout'

import * as LectureModule from 'modules/howto.lecture'

/**
 */
export const Default = ({
  components: {
    TextArea: textAreaComponents,
    Headline: headlineComponents,
    Description: descriptionComponents,
    Layout: {
      Wrapper = Layout.Wrapper,
      Header = Layout.Header,
      Body = Layout.Body,
    } = {},
  } = {},
}) => (
  <Wrapper>
    <AnkerLinks.Initializer>
      <Header>
        <Containers.BackgroundImage />
        <Containers.TextArea components={textAreaComponents}>
          <Containers.Headline components={headlineComponents} />
          <Containers.Description components={descriptionComponents} />
        </Containers.TextArea>
      </Header>
      <AnkerLinks.Default />
      <Body>
        <Sections.Default />
        <LectureModule.Initializer>
          <Lecture.Default />
        </LectureModule.Initializer>
      </Body>
    </AnkerLinks.Initializer>
  </Wrapper>
)
