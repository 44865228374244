import React from 'react'

/**
 */
const DispatchErrorFunction = () => {
  throw new Error(
    'Context is not Initialized. Please mount <Modal.Initializer>',
  )
}

/**
 */
export const Context = React.createContext({
  contents: [],
  isVisible: false,
  show: DispatchErrorFunction,
  hide: DispatchErrorFunction,
  setContents: DispatchErrorFunction,
})
