import React from 'react'

import { Context } from './Context.jsx'
import * as Styled from './Styled.jsx'

/**
 */
export const Provider = ({ children, content, options = {}, close }) => {
  return (
    <Context.Provider value={{ content, options, close }}>
      {children}
    </Context.Provider>
  )
}

/**
 */
export const Renderer = ({ content: Content }) => {
  if (typeof Content === 'function') {
    return <Content />
  }

  return <>{Content}</>
}

export const Wrapper = ({ children, isVisible }) => {
  const {
    options: { wrapOverlay, wrapContentWrapper, wrapHeader },
  } = React.useContext(Context)

  const WrappedOverlay = (props) => {
    return wrapOverlay(Overlay, props)
  }

  const WrappedContentWrapper = (props) => {
    return wrapContentWrapper(ContentWrapper, props)
  }

  const WrappedHeader = (props) => {
    return wrapHeader(Header, props)
  }

  return (
    <WrappedOverlay isVisible={isVisible}>
      <WrappedContentWrapper>
        <WrappedHeader />
        {children}
      </WrappedContentWrapper>
    </WrappedOverlay>
  )
}

const Overlay = ({ ...props }) => {
  const {
    options: { isClosable },
    close,
  } = React.useContext(Context)

  return (
    <Styled.DefaultOverlay
      {...props}
      onMouseDown={() => {
        if (isClosable) {
          close()
        }
      }}
    />
  )
}

const ContentWrapper = (props) => {
  return (
    <Styled.DefaultContentWrapper
      {...props}
      onMouseDown={(e) => e.stopPropagation()}
    />
  )
}

const Header = () => {
  const {
    options: { wrapCloseButton },
  } = React.useContext(Context)
  const WrappedCloseButton = (props) => wrapCloseButton(CloseButton, props)

  return <WrappedCloseButton />
}

const CloseButton = (props) => {
  const {
    options: { showCloseButton },
    close,
  } = React.useContext(Context)

  if (showCloseButton) {
    return (
      <Styled.DefaultCloseButton
        {...props}
        onClose={() => {
          close()
        }}
      />
    )
  }

  return null
}
