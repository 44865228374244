import React, { useMemo } from 'react'

import { Context } from './Context'
import { useStaticQuery, graphql } from 'gatsby'

/**
 */
export const Initializer = ({ language = 'ja', children }) => {
  const {
    results
  } = useStaticQuery(
    graphql`
      query {
        results: service {
          en: howtoDocuments(language: en){
            title
            documents {
              url
              title
            }
          }
          ja: howtoDocuments(language: ja){
            title
            documents {
              url
              title
            }
          }
        }
      }
    `,
  )

  const entries = useMemo(
    () => results?.[language] ?? []
    [results],
  )

  console.log(entries)

  return (
    <Context.Provider
      value={{
        entries,
      }}
    >
      {children}
    </Context.Provider>
  )
}
