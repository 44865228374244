import React from 'react'

import { Context } from './Context.jsx'
import { Contents } from './Containers.jsx'

const wrapComponent = (Component, props) => <Component {...props} />

/**
 */
export const Initializer = ({
  children,
  showOnlyLatest: isShownOnlyLatest = true,
  //
  content: defaultContent = null,
  options = {},
  isClosable = true,
  showCloseButton = isClosable,
  onShown,
  onHidden,
  wrapCloseButton = wrapComponent,
  wrapContentWrapper = wrapComponent,
  wrapOverlay = wrapComponent,
  wrapHeader = wrapComponent,

  //
  //
  //isVisible: defaultVisible = false,
  //isClosable = true,
  //showCloseButton = isClosable,
  //options: defaultOptions = {},
  //contentContainerProps = {},
  //closeButtonWrapperProps = {},
}) => {
  const [contents, setContents] = React.useState(
    defaultContent ? [{ content: defaultContent, options: {} }] : [],
  )

  const defaultOptions = {
    onShown: onShown ?? options?.onShown,
    onHidden: onHidden ?? options?.onHidden,
    isClosable,
    showCloseButton,
    wrapOverlay,
    wrapCloseButton,
    wrapContentWrapper,
    wrapHeader,
  }

  const { show, push, pop, hide } = {
    show: (content, options = {}) => {
      setContents((prev) => [{ content, options }])
    },
    push: (content, options = {}) => {
      setContents((prev) => [...prev, { content, options }])
    },
    pop: () => {
      setContents((prev) => prev.slice(0, -1))
    },
    hide: () => {
      setContents([])
    },
  }

  return (
    <Context.Provider
      value={{
        contents,
        push,
        pop,
        show,
        hide,
      }}
    >
      {children}
      <Contents options={defaultOptions} showOnlyLatest={isShownOnlyLatest} />
    </Context.Provider>
  )
}

export const Provider = Initializer
