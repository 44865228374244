import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

/**
 */
export const Title = ({ children, ...props }) => {
  return (
    <dt className={styles.Title} {...props}>
      {children}
    </dt>
  )
}

/**
 */
export const DocumentUrl = ({ children, ...props }) => {
  return (
    <div className={styles.DocumentUrl} {...props}>
      {children}
    </div>
  )
}

/**
 */
export const UrlIcon = ({ children }) => (
  <div className={styles.UrlIcon}>{children}</div>
)

/**
 */
export const HowToLinkIcon = () => <Icons.HowToLink />
