import React from 'react'

import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children, ...props }) => (
  <div className={styles.Container} {...props}>
    {children}
  </div>
)

/**
 */
export const Header = ({ title, subTitle }) => (
  <div className={styles.Header}>
    <div className={styles.Header_SubTitle}>{subTitle}</div>
    <div className={styles.Header_Title}>{title}</div>
  </div>
)

/**
 */
export const EntriesWrapper = ({ children, ...props }) => (
  <div className={styles.EntriesWrapper} {...props}>
    {children}
  </div>
)

/**
 */
export const EntryWrapper = ({ children, ...props }) => (
  <div className={styles.EntryWrapper} {...props}>
    {children}
  </div>
)

/**
 */
export const BodyWithCollapse_Container = ({ children, ...props }) => (
  <div className={styles.BodyWithCollapse_Container} {...props}>
    {children}
  </div>
)

/**
 */
export const EntriesWithCollapse_Container = ({ children, ...props }) => (
  <div className={styles.EntriesWithCollapse_Container} {...props}>
    {children}
  </div>
)

/**
 */
export const EntriesWithCollapse_Header = ({ children, ...props }) => (
  <div className={styles.EntriesWithCollapse_Header} {...props}>
    {children}
  </div>
)

/**
 */
export const EntriesWithCollapse_Body = ({ children, ...props }) => (
  <div className={styles.EntriesWithCollapse_Body} {...props}>
    {children}
  </div>
)

/**
 */
export const Thumbnail = ({
  components: { Renderer = Renderers.KeepRatioImageRenderer } = {},
  url,
  ...props
}) => <Renderer src={url} className={styles.Thumbnail} {...props} />

/**
 */
export const VideoRenderer = ({ url, ...props }) => (
  <video
    controls={true}
    src={url}
    className={styles.VideoRenderer}
    {...props}
  />
)

/**
 */
export const Number = ({ children }) => (
  <div className={styles.Number}>{children}</div>
)

/**
 */
export const Open = () => <div className={styles.Open}>▶</div>

/**
 */
export const Close = () => <div className={styles.Close}>▼</div>
