import React, { useMemo } from 'react'

import { useMediaQuery } from 'react-responsive'

import { useLectures } from 'modules/howto.lecture/useLectures'

import * as Styled from './Styled'
import * as Containers from './Containers'

import { Name } from 'partials/CasesPage/AnkerLinks/Initializer'

/**
 */
export const Default = ({
  components: { Container = Styled.Container } = {},
}) => {
  const { entries } = useLectures()

  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <Container id={Name.Lecture}>
      <Containers.Header />
      {isMobile ? (
        <Containers.BodyWithCollapse entries={entries} />
      ) : (
        <Containers.Body entries={entries} />
      )}
    </Container>
  )
}
