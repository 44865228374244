import React from 'react'

import { css } from '@emotion/react'
import * as Icons from '@arch-log/webapp.shared/Icons'

/**
 */
export const DefaultOverlay = ({ isVisible, children, ...props }) => (
  <div
    css={css`
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.8);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
      padding: 10px;
    `}
    style={{
      visibility: isVisible ? 'visible' : 'hidden',
      display: isVisible ? null : 'none',
    }}
    {...props}
  >
    {children}
  </div>
)

export const DefaultCloseButton = ({ props, onClose }) => {
  return (
    <div
      css={css`
        z-index: 1000;
        position: absolute;
        top: 12px;
        right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
      {...props}
    >
      <div
        onClick={onClose}
        css={css`
          cursor: pointer;
          width: 27px;
          height: 27px;
          padding: 6px;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 50%;
        `}
      >
        <Icons.ModalClose />
      </div>
    </div>
  )
}

export const DefaultContentWrapper = ({ children, ...props }) => (
  <div
    css={css`
      position: relative;
      //max-width: 80vw;
      background: white;
      color: #aaa;
      max-height: 98vh;
      overflow-y: auto;
      overflow-x: hidden;
    `}
    {...props}
  >
    {children}
  </div>
)
