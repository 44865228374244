import React from 'react'

import * as Document from '../Document'

import { Context } from '../Context'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 */
export const Each = ({ render: Renderer = ChildrenRenderer, ...props }) => {
  const {
    documents ,
  } = React.useContext(Context)

  return documents.map((document, index) => {
    return (
      <Document.Provider key={index} document={document}>
        <Renderer {...props} />
      </Document.Provider>
    )
  })
}
