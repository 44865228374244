import React from 'react'

import { Context } from './Context'
import { Link } from '@arch-log/webapp.shared/Link'

export const Url = ({ render: Renderer = Link, ...props }) => {
  const {
    title, url,
  } = React.useContext(Context)

  if (!url) {
    return null
  }

  return (
    <Renderer to={url} alt={title} {...props}>
      {title}
    </Renderer>
  )
}
