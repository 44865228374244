import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Header = ({ title = '', subTitle = '' }) => (
  <div className={styles.Header}>
    <div className={styles.Header_SubTitle}>{subTitle}</div>
    <div className={styles.Header_Title}>{title}</div>
  </div>
)
