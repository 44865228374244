import React, { createContext, useContext, useMemo } from 'react'

import { useTranslation } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
const Context = createContext()

/**
 */
export const Name = {
  HowTo: 'howto',
  Lecture: 'lecture',
}

/**
 */
export const Initializer = ({ children }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('app')

  const entries = useMemo(
    () =>
      Object.entries(Name).map((key) => ({
        name: key[1],
        label: t(`CasesPage.${key[0]}.Header.Title`),
      })),

    [language],
  )

  return <Context.Provider value={{ entries }}>{children}</Context.Provider>
}

/**
 */
export const useAnkerLinks = () => useContext(Context)
