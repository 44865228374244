import React from 'react'

import { useTranslation } from '@arch-log/webapp.lib/TranslationHelper'

import * as DocumentGroups from 'modules/howto.document'
import * as Styled from './Styled'

import * as StyledBase from 'partials/CasesPage/SectionBase/Styled'

/**
 */
export const Header = ({
  components: { Decorator = StyledBase.Header } = {},
}) => {
  const { t } = useTranslation('app')

  const title = t('CasesPage.HowTo.Header.Title')

  return <Decorator title={title} />
}

/**
 */
export const Title = ({ components: { Decorator = Styled.Title } = {} }) => {
  return (
    <Decorator>
      <DocumentGroups.Entry.Title />
    </Decorator>
  )
}

/**
 */
export const DocumentUrl = ({
  components: { Decorator = Styled.DocumentUrl, UrlIcon = Styled.UrlIcon } = {},
}) => {
  return (
    <Decorator>
      <UrlIcon>
        <Styled.HowToLinkIcon />
      </UrlIcon>
      <DocumentGroups.Entry.Document.Url target="_blank" rel="noopener noreferrer" />
    </Decorator>
  )
}
