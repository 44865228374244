import React from 'react'

import { Context } from './Context.jsx'
import * as ContentComponents from './Content/Containers.jsx'

export const Contents = ({
  showOnlyLatest: isShownOnlyLatest = true,
  options: defaultOptions = {},
}) => {
  const { contents, pop } = React.useContext(Context)

  return contents.map(({ content, options = {} }, idx) => {
    const isVisible = !isShownOnlyLatest || contents.length - 1 === idx

    return (
      <ContentComponents.Provider
        key={idx}
        content={content}
        options={{ ...defaultOptions, ...options }}
        close={() => {
          pop()
        }}
      >
        <ContentComponents.Wrapper
          isVisible={isVisible}
          {...defaultOptions}
          {...options}
        >
          <ContentComponents.Renderer content={content} />
        </ContentComponents.Wrapper>
      </ContentComponents.Provider>
    )
  })
}

